import Auth from '@javascript/lib/auth';

/* eslint-disable eqeqeq */
export function initMenu() {
  const OPEN_SUBMENU_HANDLER = 'openSubmenuHandler';
  const BODY_CLICK_HANDLER = 'bodyClickHandler';
  const OPTION_CHANGE_HANDLER = 'optionChangeHandler';
  const MENU_BTN_HANDLER = 'menuBtnHandler';
  const CLOSE_BTN_HANDLER = 'closeBtnHandler';
  const BACK_BTN_HANDLER = 'backBtnHandler';
  const ESCAPE_KEY_HANDLER = 'escapeKeyHander';
  const LOGIN_BTN_HANDLER = 'loginBtnHandler';

  let windowWidth = window.innerWidth;

  const eventListeners = {
    handlers: {},
    attach: function (element, handlerName, callback, eventType = 'click') {
      // Check if there's an existing handler for this element and remove it
      if (this.handlers[element] && this.handlers[element][handlerName]) {
        element.removeEventListener(
          eventType,
          this.handlers[element][handlerName]
        );
      }

      if (!this.handlers[element]) {
        this.handlers[element] = {};
      }

      this.handlers[element][handlerName] = callback;

      if (callback) {
        element.addEventListener(
          eventType,
          this.handlers[element][handlerName]
        );
      }
    },
  };

  class FocusTrap {
    constructor(container, backButton) {
      this.backButton = backButton;
      this.container = container;
      this.focusableElementsString =
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
      this.updateFocusableElements();
      this.handleKeydown = this.handleKeydown.bind(this);
    }

    updateFocusableElements() {
      this.focusableElements = this.container.querySelectorAll(
        this.focusableElementsString
      );

      this.firstFocusableElement = this.backButton || this.focusableElements[0];
      this.lastFocusableElement =
        this.focusableElements[this.focusableElements.length - 1];
    }

    activate() {
      this.updateFocusableElements();
      document.addEventListener('keydown', this.handleKeydown);
      this.firstFocusableElement.focus();
    }

    deactivate() {
      document.removeEventListener('keydown', this.handleKeydown);
    }

    handleKeydown(event) {
      if (event.key === 'Tab') {
        if (event.shiftKey) {
          if (document.activeElement === this.firstFocusableElement) {
            event.preventDefault();
            this.lastFocusableElement.focus();
          }
        } else {
          if (document.activeElement === this.lastFocusableElement) {
            event.preventDefault();
            this.firstFocusableElement.focus();
          }
        }
      }
    }
  }

  class UI {
    constructor() {
      this.menuButton = document.querySelector(
        '[data-nav-id="nav-menu-button"]'
      );
      this.closeButton = document.querySelector(
        '[data-nav-id="nav-close-button"]'
      );
      this.navWrapper = document.querySelector('[data-nav-id="nav-wrapper"]');
      this.navLogo = document.querySelector('[data-nav-id="nav-menu-logo"]');
      this.backButton = document.querySelector('[data-nav-id="nav-menu-back"]');
      this.optionSelect = document.querySelectorAll('.ac-nav__link--select');
      this.mainNav = document.querySelector('[data-level="0"]');
      this.navs = document.querySelectorAll('.ac-nav');
      this.submenus = document.querySelectorAll('.ac-nav__link--submenu');
      this.acBody = document.querySelector('body');
      this.navHeader = document.querySelector('.ac-nav__mobile-header');
      this.loginBtn = document.querySelector('.ac-nav__login');
      this.loginLabel = document.querySelector('.ac-nav__login--label');

      this.setupLogin();

      this.unlockScroll();
    }

    hasClass(ele, cls) {
      return ele.getAttribute('class').indexOf(cls) > -1;
    }

    addClass(ele, cls) {
      if (ele.classList) {
        ele.classList.add(cls);
      } else if (!this.hasClass(ele, cls)) {
        ele.setAttribute('class', ele.getAttribute('class') + ' ' + cls);
      }
    }

    removeClass(ele, cls) {
      if (ele.classList) {
        ele.classList.remove(cls);
      } else if (this.hasClass(ele, cls)) {
        ele.setAttribute('class', ele.getAttribute('class').replace(cls, ' '));
      }
    }

    setVisibility(element, show = true) {
      if (show) {
        this.removeClass(element, 'ac-nav--hidden');
        element.setAttribute('aria-hidden', 'false');
      } else {
        this.addClass(element, 'ac-nav--hidden');
        element.setAttribute('aria-hidden', 'true');
      }
    }

    setDisplay(element, show = true) {
      if (show) {
        this.removeClass(element, 'ch-display--none');
        this.addClass(element, 'ch-display--block');
        element.setAttribute('aria-hidden', 'false');
      } else {
        this.removeClass(element, 'ch-display--block');
        this.addClass(element, 'ch-display--none');
        element.setAttribute('aria-hidden', 'true');
      }
    }

    pushAnalytics(eventCategory, eventAction, eventLabel) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'GAEvent',
          eventCategory,
          eventAction,
          eventLabel,
        });
      }
    }

    lockScroll() {
      this.addClass(this.acBody, 'ac-body--scrolling-locked');
    }

    unlockScroll() {
      this.removeClass(this.acBody, 'ac-body--scrolling-locked');
    }

    async setupLogin() {
      const auth = await new Auth().initializeAuthClient(window.authOptions);
      const isAuthenticated = await auth.isAuthenticated();

      const onClick = isAuthenticated ? auth.goToProfile : () => auth.login();

      if (isAuthenticated) {
        //remove tracking from login button
        this.loginBtn.removeAttribute('data-gtm-track');
        this.loginBtn.removeAttribute('data-gtm-track-label');

        this.loginLabel.textContent = 'Your account';
      }

      eventListeners.attach(
        this.loginBtn,
        LOGIN_BTN_HANDLER,
        onClick.bind(auth)
      );
    }
  }

  class DesktopMenu extends UI {
    constructor() {
      super();

      this.attachEventListeners();
    }

    attachEventListeners() {
      for (let i = 0; i < this.submenus.length; i++) {
        eventListeners.attach(
          this.submenus[i],
          OPEN_SUBMENU_HANDLER,
          this.toggleSubmenu.bind(this)
        );
      }

      eventListeners.attach(
        this.acBody,
        BODY_CLICK_HANDLER,
        this.handleBodyClick.bind(this)
      );

      eventListeners.attach(
        document,
        ESCAPE_KEY_HANDLER,
        this.handleKeyPress.bind(this),
        'keyup'
      );
    }

    handleKeyPress(event) {
      if (event.key === 'Escape') {
        this.closeAllSections();
      }
    }

    handleBodyClick(event) {
      if ([...this.submenus].includes(event.target)) return;

      // Get any open submenu (desktop)
      const openSubmenu = document.querySelector(
        '.ac-nav--submenu:not(.ac-nav--hidden)'
      );

      if (openSubmenu) {
        // Get the bounding rect of the open submenu
        const submenuRect = openSubmenu.getBoundingClientRect();

        // Check if the click was outside the bounding rectangle of the submenu
        if (
          event.clientY < submenuRect.top ||
          event.clientY > submenuRect.bottom
        ) {
          // Close submenu
          this.closeAllSections();
        }
      }
    }

    closeAllSections() {
      for (let i = 0; i < this.submenus.length; i++) {
        if (this.submenus[i].nextElementSibling) {
          this.setVisibility(this.submenus[i].nextElementSibling, false);
        }
      }

      this.unlockScroll();
    }

    toggleSubmenu(event) {
      event.preventDefault();
      const { nextElementSibling } = event.target;
      if (!nextElementSibling) return;

      const isHidden = this.hasClass(nextElementSibling, 'ac-nav--hidden');

      this.closeAllSections();

      if (isHidden) {
        this.setVisibility(event.target.nextElementSibling, true);
        this.lockScroll();
      }
    }
  }

  class MobileMenu extends UI {
    constructor() {
      super();

      this.previousLevel = null;
      this.shouldTrapFocus = false;

      this.attachEventListeners();

      this.reset();
    }

    reset() {
      this.closeAllSections();
      this.setDisplay(this.navLogo, true);
      this.setDisplay(this.backButton, false);
      this.setVisibility(this.navs[0], true);

      // set scroll lock if wrapper is open
      if (this.hasClass(this.navWrapper, 'ac-nav__wrapper--open')) {
        this.lockScroll();
        this.navHeader.scrollIntoView();
      }
    }

    attachEventListeners() {
      for (let i = 0; i < this.submenus.length; i++) {
        eventListeners.attach(
          this.submenus[i],
          OPEN_SUBMENU_HANDLER,
          this.goTo.bind(this)
        );
      }

      for (let i = 0; i < this.optionSelect.length; i++) {
        eventListeners.attach(
          this.optionSelect[i],
          OPTION_CHANGE_HANDLER,
          this.goToPage.bind(this),
          'change'
        );
      }

      if (this.menuButton !== null) {
        eventListeners.attach(
          this.menuButton,
          MENU_BTN_HANDLER,
          this.openNavigation.bind(this)
        );
      }

      if (this.menuButton !== null) {
        eventListeners.attach(
          this.menuButton,
          MENU_BTN_HANDLER,
          this.openNavigation.bind(this),
          'keydown'
        );
      }

      if (this.closeButton !== null) {
        eventListeners.attach(
          this.closeButton,
          CLOSE_BTN_HANDLER,
          this.closeNavigation.bind(this)
        );
      }

      if (this.backButton !== null) {
        eventListeners.attach(
          this.backButton,
          BACK_BTN_HANDLER,
          this.goBack.bind(this)
        );
      }

      eventListeners.attach(
        document,
        ESCAPE_KEY_HANDLER,
        this.handleKeyPress.bind(this),
        'keyup'
      );

      // Unset desktop body click
      eventListeners.attach(this.acBody, BODY_CLICK_HANDLER, null);
    }

    goToPage(event) {
      const target = event.target;
      const url = target.value;

      if (!url.startsWith('#')) {
        const dataset = target[event.target.selectedIndex].dataset;

        const category = dataset.category; // 'UX - Nav';
        const action = dataset.action; // 'Find a car - Manufacturer offers';
        const label = dataset.label; // e.g. 'Ford'

        this.pushAnalytics(category, action, label);
        target.selectedIndex = 0;

        window.location.href = url;
      }
    }

    closeAllSections() {
      for (let i = 0; i < this.navs.length; i++) {
        this.setVisibility(this.navs[i], false);
      }
    }

    goTo(event) {
      event.preventDefault();
      const openEl = event.target.nextElementSibling;

      this.closeAllSections();

      // Show the new menu
      this.setVisibility(openEl, true);

      // Hiding the logo
      this.setDisplay(this.navLogo, false);

      // show back button
      this.setDisplay(this.backButton);

      this.previousLevel = event.target.dataset.parentLevel;

      this.navHeader.scrollIntoView();

      const backButton = document.querySelector(
        '[data-nav-id="nav-menu-back"]'
      );

      if (this.shouldTrapFocus) {
        this.focusTrap.deactivate();
        this.focusTrap = new FocusTrap(openEl, backButton);
        this.focusTrap.activate();
      }
    }

    goBack() {
      const navToActivate = Array.from(this.navs).find(
        (nav) => nav.dataset.level == this.previousLevel
      );

      if (this.previousLevel == 0) {
        this.setDisplay(this.navLogo);

        // Hiding the back button
        this.setDisplay(this.backButton, false);
      }

      this.closeAllSections();

      // Showing the new menu
      this.setVisibility(navToActivate);

      this.previousLevel =
        navToActivate.parentNode.closest('nav').dataset.level;

      this.navHeader.scrollIntoView();

      if (this.shouldTrapFocus) {
        this.focusTrap.deactivate();
        this.focusTrap = new FocusTrap(this.navWrapper);
        this.focusTrap.activate();
      }
    }

    openNavigation(event) {
      this.addClass(this.acBody, 'ac-body--scrolling-locked');
      this.addClass(this.navWrapper, 'ac-nav__wrapper--open');
      this.navWrapper.setAttribute('aria-expanded', 'true');
      this.shouldTrapFocus = event.type === 'keydown';
      if (this.shouldTrapFocus) {
        this.focusTrap = new FocusTrap(this.navWrapper);
        this.focusTrap.activate();
      }
    }

    closeNavigation() {
      this.closeAllSections();

      // Hide Back button
      this.setDisplay(this.backButton, false);

      // Show the logo
      this.setDisplay(this.navLogo);

      // show the main menu
      this.setVisibility(this.mainNav);

      // Hide the whole menu wrapper
      this.removeClass(this.navWrapper, 'ac-nav__wrapper--open');

      this.removeClass(this.acBody, 'ac-body--scrolling-locked');

      this.navWrapper.setAttribute('aria-expanded', 'false');

      if (this.shouldTrapFocus) {
        this.focusTrap.deactivate();
      }
    }

    handleKeyPress(event) {
      if (event.key === 'Escape') {
        this.closeNavigation();
      }
    }
  }

  // MenuFactory class
  class MenuFactory {
    static createMenu(isResize = false) {
      const isDesktop = window.matchMedia('(min-width: 992px)').matches;

      if (isResize && windowWidth === window.innerWidth) return;

      windowWidth = window.innerWidth;

      if (isDesktop) {
        return new DesktopMenu();
      }

      return new MobileMenu();
    }
  }

  class Util {
    static throttle(callback, delay) {
      let lastCall = 0;
      return function () {
        const now = new Date().getTime();
        if (now - lastCall >= delay) {
          callback.apply(null, arguments);
          lastCall = now;
        }
      };
    }
  }

  MenuFactory.createMenu();

  const throttledCreateMenu = Util.throttle(MenuFactory.createMenu, 20);
  window.addEventListener('resize', throttledCreateMenu.bind(this, true));
}

initMenu();
